import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RelatedProducts from "../components/related-product"
import TwoThirds from "../components/two-thirds-layout"
import ProductHeader from "../components/product-head-section"
import Tabs from "../components/product-tabs"
import Table from "../components/product-table"
import GenForm from "../components/gen-contact-form"
import GenButton from "../components/gen-button"
import ImageGallery from "../components/main-image-slider"

export default function ProductTemplate({ data }) {
    const page = data.product
    const related = data.related
    const content = data.product.main_content

    //sample sidebar
    const productSideBar = [
          {
            "link": "shop/",
            "button": "Product List",
            "rel": "noopener",
            "targetLink": "_self",
            "color": "white"
          },
          {
            "link": "https://store.ifi-test.com/collections",
            "button": "Shopify Store",
            "rel": "noopener",
            "targetLink": "_target",
            "color": "white"
          }
    ]
    
    const sampleSidebar = productSideBar.map((el, index) => {
        return <GenButton key={index} link={el.link} button={el.button} rel={el.rel} target={el.targetLink} color={el.color} />
    })

    //content
    const contentPage = [];
    content.forEach((el, index) => {
        let curEl;
        if(el.type === "header"){
            curEl = <ProductHeader key={index} name={page.name} price={page.start_price} excerpt={page.excerpt} image={page.image_thumb} />
        } else if(el.type === "table") {
            curEl = <Table key={index} title={page.name} tablecontent={page.variations} />
        } else if(el.type === "tabs") {
            curEl = <Tabs key={index} tabs={page.tab} sku={page.id} price={page.start_price} name={page.name} url={page.url} image={page.image_thumb} />
        } else if (el.type === "gallery") {
            curEl = <ImageGallery key={index} gallerycontent={page.image_gallery} />
        }
        contentPage.push(curEl);
    })

    return (
        <Layout>
            <SEO title={page.seo.title} description={page.seo.description} />
            <TwoThirds left={contentPage} right={sampleSidebar}/>
            <RelatedProducts products={related}/>
            <GenForm />
        </Layout>
    )
}

export const query = graphql`
    query($url: String!, $related: String) {
        product: productsJson(url: { eq: $url}) {
            id,
            url,
            name,
            start_price,
            image_thumb,
            button,
            excerpt,
            seo {
                title,
                description,
                keywords,
                author,
                og_type,
                og_image,
                twitter_card,
                twitter_image
            },
            main_content {
                type
            },
            tab {
                tab_name,
                id,
                tab_content_html
            },
            image_gallery {
                image,
                alt
            },
            variations {
                sku,
                product_name,
                excerpt,
                us_price
            }
        }
        related: allProductsJson (
            filter: {group: { eq: $related}}
        ){
            edges {
                node {
                    id,
                    url,
                    name,
                    group,
                    start_price,
                    image_thumb,
                    button,
                    excerpt
                }
            }
        }
    }
`