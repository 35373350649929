import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function MainImageSlider({gallerycontent}) {

  let gallery = [];

  const data = useStaticQuery(graphql`
        query{
            productimg: allFile(filter: {relativePath: {regex: "/images\/products/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                          gatsbyImageData(
                            placeholder: BLURRED
                          )
                        }
                    }
                }
            }
        }
    `)

  gallerycontent.forEach((el, index) => {
    let curImage, gotCurImage;
    if(el.image.length > 0) {
        let imgSource = el.image;
        const image = data.productimg.edges.find(n => {
            return n.node.relativePath.includes(imgSource)
        })

        if(!image) {
            return null;
        }

        gotCurImage = getImage(image.node)
        curImage = <GatsbyImage key={index + "+" + el.image} image={gotCurImage} alt={el.alt} />
    }
     
     gallery.push(curImage)
     
})

  return gallery;

};