import React , { useState } from "react";
import * as ptabsstyles from "./product-tabs.module.css"

export default function ProductTabs({tabs, sku, price, name, url, image}) {
    const [description, setDescription] = useState(true);
    const [review, setReview] = useState(false);
    // const [tab, setTab] = useState("");
    
    let calcReview, showReview, calcDescription, showDescription;

    tabs.forEach((el, index) => {
        
        function createMarkup() {
            return {__html: el.tab_content_html}
        }
        if(el.tab_name === "Description"){
            calcDescription = <div key={index} dangerouslySetInnerHTML={createMarkup()}></div>
        }else {
            calcReview = <div>
                <div key={index} dangerouslySetInnerHTML={createMarkup()} ></div>
                <div>
                    <p>In the meantime, leave us a review</p>
                    <p>
                        <a href="https://g.page/IndependentForensics/review?np" target="_blank" rel="noopener noreferrer">Google Reviews</a>
                    </p>
                   <p>
                        <a href="https://www.facebook.com/IndependentForensicsDNA/reviews/" target="_blank" rel="noopener noreferrer">Facebook Reviews</a>
                   </p>
                   <p>
                        <a href="https://www.trustpilot.com/evaluate/ifi-test.com" target="_blank" rel="noopener noreferrer">Trust Pilot Reviews</a>
                   </p>
                </div>
                </div>
        }
    })

    if(description) {
        showDescription = calcDescription
    } else {
        showDescription = null;
    }

    if(review) {
        showReview = calcReview
    } else {
        showReview = null;
    }

    function openTab(curTab) {
        setReview(!review)
        setDescription(!description)
    }

    return (
        <div className={ptabsstyles.tabmain}>
            <div className={ptabsstyles.tabcolumnone}>
                <button disabled={description} className={(description ? [ptabsstyles.btntab, ptabsstyles.active].join(" ") : [ptabsstyles.btntab, ptabsstyles.inactive].join(" "))} onClick={(e) => openTab("description", e)}>Description</button>
                <button disabled={review} className={(review ? [ptabsstyles.btntab, ptabsstyles.active].join(" ") : [ptabsstyles.btntab, ptabsstyles.inactive].join(" "))}onClick={(e) => openTab("reviews", e)} >Reviews</button>
            </div>
            <div className={ptabsstyles.tabcolumntwo}>
                <div className={ptabsstyles.tabcontent}>
                    {showDescription}
                    {showReview}
                </div>
            </div>
        </div>
    )
}