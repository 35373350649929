import React from "react"
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'
import GenButton from "../components/gen-button"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import * as relatedproductstyles from "./related-product.module.css" 

export default function RelatedProduct({products}) {
    const data = useStaticQuery(graphql`
      query{
        image: allFile(filter: {relativePath: {regex: "/images\/products/"}}){
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 200, maxHeight: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)

    const params = {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      }

    let edgesProduct = products.edges
    let slides, img;
    slides= [];

    edgesProduct.forEach(({node}, index) => {
      let curSlide;
      if(node.image_thumb.length > 0) {
        let imgSource = node.image_thumb;
        const curImage = data.image.edges.find(n => {
          return n.node.relativePath.includes(imgSource)
        })
        if(!curImage) {
          return null;
        }
        
        img = <Img fluid={curImage.node.childImageSharp.fluid} className={relatedproductstyles.imgthumb} />
      }
      curSlide = <div className={relatedproductstyles.relatedcards} key={index}>
        <div className={relatedproductstyles.padding2rem}>
          {img}
          <Header headerText={node.name} classTag="h6" headingTag="h4" />
          <p>${node.start_price}USD</p>
          <GenButton link={"product/" + node.url} />
        </div>
      </div>;
      slides.push(curSlide);
    })

    return (
        <section className="small">
            <div className="center-text">
                <Header headerText="Related" classTag="h3" headingTag="h3" />
            </div>
            <Swiper {...params}>
                {slides}
            </Swiper>
        </section>
    )
}