import React from "react"
import * as producttablestyles from "./product-table.module.css"
import Item from "../components/product-table-item";
import Header from "../components/header"

const ProductTable = ({tablecontent, title}) => {

    const tableHeader = [
        "sku", "", "", "qty", "add", "usd", "description"
    ]
    
    return (
        <section className="small">
            <div>
                <Header headerText={title} classTag="h3" headingTag="h2" />
            </div>
            <div className={producttablestyles.overflow} >
                <table className={[producttablestyles.overflow, producttablestyles.gentable].join(" ")}>
                    <thead>
                        <tr>
                            {
                                tableHeader.map((el, index) => (
                                    <th key={index + "header"}>{el}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                       {
                           tablecontent.map((el, index) => (
                            <Item key={index + "body"} name={el.product_name} sku={el.sku} price={el.us_price} />
                           ))
                       }
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default ProductTable;