import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header"

export default function TwoThirds({ left, right }){
    const mainDiv = {
        display: "flex",
        justifyContent: "space-between"
    }
    return (
        <div style={mainDiv}>
            <div className="two-thirds">
                <section className="content">
                    {left}
                </section>
            </div>
            <div className="one-third">
                <div className="sidebar">
                    <section className="content center-text">
                        <Header headerText="QuickLinks" classTag="h3" headingTag="h3" />
                        {right} 
                    </section>
                </div>
            </div>
        </div>
    )
}

TwoThirds.propTypes = {
    left: PropTypes.array,
    right: PropTypes.array
}

TwoThirds.defaultProps = {
    left: null,
    right: null
}