import React, {useState} from "react"
import * as productitemstyles from "./product-table-item.module.css"
import { connect } from 'react-redux';
import { addItem } from '../state/app';

const Item = ({cart, dispatch, price, name, sku}) => {

    const [count, setCount] = useState(0)
    const curItem = {
        sku: sku,
        price: price,
        qty: count,
        name: name
    }

    function add() {
        // console.log(curItem)
        setCount(0)
        let newCart, curCart = []
        curCart = [...cart]

        if(curCart.length > 0) {
           //check curItem exist in curCart by sku
           //if true then update qty
           //if false add to newCart
           let checkSku = curCart.every(el => el.sku !== curItem.sku)
           if(!checkSku){
            //console.log("found element fired")
                newCart = curCart.map(el => {
                    if(el.sku !== curItem.sku) {
                        return el
                    } else {
                        return {sku: el.sku, price: price, qty: el.qty + curItem.qty, name: el.name}
                    }
                } )
           } else {
               newCart = [...cart, curItem];
           }
        } else {
            newCart = [...cart, curItem]
        }

        dispatch(addItem(newCart))
    }

    return (
        <tr>
            <td>{sku}</td>
            <td><button onClick={()=> setCount(count  - 1)} className={(count === 0 ? [productitemstyles.genbtncounter].join(" ") : ["blue", productitemstyles.genbtncounter].join(" "))} disabled={count === 0} >-</button></td>
            <td><button className={["blue", productitemstyles.genbtncounter].join(" ")} onClick={()=> setCount(count  + 1)}>+</button></td>
            <td>{ count }</td>
            <td><button className={(count > 0 ? [productitemstyles.genbtncounter, "blue"].join(" ") : [productitemstyles.genbtncounter].join(" "))} disabled={count === 0} onClick={add}>Cart</button></td>
            <td>{price > 0 ? "$" + price : "Inquire Only"}</td>
            <td>{name}</td>
        </tr>
    )
}

export default connect( state => ({
    cart: state.app.cart
}), null)(Item)