import React from "react"
import Header from "../components/header"
import Message from "../components/product-special-message"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import * as productheadsectionstyles from "./product-head-section.module.css"

export default function ProductHeader({name, price, excerpt, image}) {

    const data = useStaticQuery(graphql`
        query {
            productimg: allFile(filter: {relativePath: {regex: "/images\/products/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 500) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

    let calcExcerpt, calcImage;

    if(excerpt.length > 0) {
        calcExcerpt = <p>{excerpt}</p>;
    } else {
        calcExcerpt = null;
    }

    if(image.length > 0) {
        let imgSource = image;
        const curImage = data.productimg.edges.find(n=>{
            return n.node.relativePath.includes(imgSource)
        })

        if(!curImage) {
            return null;
        }
        calcImage = <Img fluid={curImage.node.childImageSharp.fluid} />
    } else {
        calcImage = null;
    }

    return (
        <div className={productheadsectionstyles.productmainheader}>
            <div className={[productheadsectionstyles.productcolumn, "center-text"].join(" ")}>
                {calcImage}
            </div>
            <div className={productheadsectionstyles.productcolumnwide}>
                <section className="small">
                    <Header headerText={name} /> 
                    {calcExcerpt}
                    <p>Starting at ${price} USD</p>
                    <Message />
                </section>
            </div>
        </div>
    )
}